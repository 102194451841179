.main-container-spt {
  height: 100%;
  width: 100%;
  background-image: url("../../static/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  padding-top: 20px;
}

.infoarea-wrapper-spt {
  height: 200px;
  padding: 0 50px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.infoare-spt {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.315);
}

.infos-wrapper-spt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 25%;
}

.infos-wrapper-spt h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #212121;
  margin-top: 10px;
  margin-bottom: 10px;
}

.infos-wrapper-spt p {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #212121;
}

@media only screen and (max-width: 1024px) {
  .infos-wrapper-spt h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #212121;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .infos-wrapper-spt p {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 11px;
    color: #212121;
  }
}

@media only screen and (max-width: 1023px) {
  .main-container-spt {
    padding-top: 0px;
  }

  .infoarea-wrapper-spt {
    display: none;
  }
}
