.main-container-fr-spt {
  height: 100%;
  width: 100%;
  background-image: url("../../static/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  padding-top: 20px;
}

.mob-flights-wrapper-fr-spt {
  display: none;
}

.search-again-fr-spt {
  width: 100%;
  height: 150px;
  background-color: #ffffff;
  margin-top: -5px;
  padding: 20px 50px;
}

.flights-wrapper-fr-spt {
  display: flex;
  width: 100%;
  height: 600px;
  margin-top: -5px;
  padding: 0 50px;
}

@media only screen and (max-width: 1023px) {
  .main-container-fr-spt {
    display: none;
  }

  .mob-flights-wrapper-fr-spt {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }
}
