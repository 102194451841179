.single-flight-wrapper-spt {
  display: flex;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #c5c5c5;
  margin-bottom: 5px;
}

.single-flight-spt {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 20%;
  height: 100%;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #212121;
}

.airline-area-sf-spt {
  color: #0092cf;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.airline-area-sf-spt h6 {
  cursor: pointer;
}

.airline-flex-sf-spt {
  display: flex;
  width: 90%;
  height: 25px;
}

.airline-share-sf-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.refundable-sf-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2px 10px;
  color: white;
  background-color: #0092cf;
  font-size: 11px;
}

.middle-big-single-spt {
  flex: 3 0 auto;
}

.departure-area-sf-spt {
  display: flex;
  width: 100%;
  height: 100%;
}

.icon-departure-sf-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
}

.title-departure-sf-spt {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 70%;
  height: 100%;
}

.title-departure-sf-spt p {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.text-departure-sf-spt {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 70%;
  height: 100%;
}

.text-departure-sf-spt p {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.arrival-area-sf-spt {
  display: flex;
  width: 100%;
  height: 100%;
}

.icon-arrival-sf-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
}

.title-arrival-sf-spt {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 70%;
  height: 100%;
}

.title-arrival-sf-spt p {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.text-arrival-sf-spt {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 70%;
  height: 100%;
}

.text-arrival-sf-spt p {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.text-duration-sf-spt {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.text-duration-sf-spt p {
  margin: 5px 0px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.price-area-sf-spt h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.book-button-sf-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px 0px;
  height: 30px;
  width: 100px;
  background-color: #0092cf;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: white;
  cursor: pointer;
}
