.search-form-wrapper-spt {
  width: 100%;
  height: 110px;
}

.search-form-container-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 110px;
}

.type-modify-search-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 60%;
  margin: 0px 5px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.select-modify-type-spt p {
  color: #0092cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-left: 15px;
}

.select-modify-type-spt select {
  width: 100%;
  padding: 5px 10px;
  display: inline-block;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.departurefrom-modify-search-spt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 60%;
  margin: 0px 5px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.departurefrom-modify-search-spt p {
  color: #0092cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.departurefrom-modify-search-spt input[type="text"] {
  width: 100%;
  padding: 5px 10px;
  display: inline-block;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.destinationicon-modify-wrapper-spt {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 23%;
  height: 33%;
  margin: 0px 5px;
  border-radius: 50px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
}

.arrivalto-modify-search-spt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 60%;
  margin: 0px 5px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.arrivalto-modify-search-spt p {
  color: #0092cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.arrivalto-modify-search-spt input[type="text"] {
  width: 100%;
  padding: 5px 10px;
  display: inline-block;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.departuredt-modify-search-spt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 170px;
  height: 60%;
  margin: 0px 5px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.departuredt-modify-search-spt p {
  color: #0092cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.departuredt-modify-search-spt input[type="date"] {
  width: 95%;
  padding: 5px 10px;
  display: inline-block;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.arrivaldt-modify-search-spt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 170px;
  height: 60%;
  margin: 0px 5px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.arrivaldt-modify-search-spt p {
  color: #0092cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.arrivaldt-modify-search-spt input[type="date"] {
  width: 95%;
  padding: 5px 10px;
  display: inline-block;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.passenger-modify-search-spt {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 60%;
  margin: 0px 5px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.passenger-modify-search-spt p {
  color: #0092cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-left: 10px;
}

.passengercount {
  display: flex;
  justify-content: center;
  width: 200px;
}

.passengercount p {
  width: 100%;
  padding: 5px 0px;
  display: inline-block;
  border: none;
  text-align: left;
  color: #484848;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.passengercountdisplay-spt {
  display: none;
}

.passengercountwrapper-spt {
  display: block;
}

.passengercountcontainer-spt {
  position: absolute;
  top: 55%;
  left: 46%;
  z-index: 999;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  height: 150px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.passengercountlist1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.select-modify-class-spt {
  width: 100%;
  height: 40px;
  margin: 15px;
}

.select-modify-class-spt select {
  width: 100%;
  padding: 10px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.adult-modify-count-spt {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 40px;
  margin: 15px;
  padding: 0px 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.adult-modify-count-spt p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.adultcounter-modify-spt {
  background-color: #e9e9e9;
  padding: 10px;
  margin-left: 5px;
}

.children-modify-count-spt {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 40px;
  margin: 15px;
  padding: 0px 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.children-modify-count-spt p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.childrencounter-modify-spt {
  background-color: #e9e9e9;
  padding: 10px;
  margin-left: 5px;
}

.infant-modify-count-spt {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 40px;
  margin: 15px;
  padding: 0px 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.infant-modify-count-spt p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.infantcounter-modify-spt {
  background-color: #e9e9e9;
  padding: 10px;
  margin-left: 5px;
}

.passengercountlist2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.submit-modify-search-spt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  height: 60%;
  margin: 0px 5px;
}

.submit-modify-search-spt input[type="submit"] {
  width: 100%;
  padding: 10px 5px;
  display: inline-block;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
  background-color: #0092cf;
  border-radius: 20px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  color: white;
  text-align: center;
}
