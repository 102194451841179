.topbar-wrapper-spt {
  display: flex;
  height: 100px;
  padding: 0 50px;
}

.topbar-mob-wrapper-spt {
  display: none;
}

.logo-wrapper-spt {
  display: flex;
  align-items: center;
  width: 30%;
  height: 100%;
}

.logo-box-spt {
  width: 250px;
}

.auth-wrapper-spt {
  display: flex;
  justify-content: flex-end;
  width: 70%;
  height: 100%;
}

.corporate-wrapper-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
}

.corporate-icon-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0092cf;
  color: #ffffff;
  height: 35px;
  width: 35px;
  border-radius: 8px 0 0 8px;
}

.corporate-text-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 35px;
  width: 150px;
  border-radius: 0 8px 8px 0;
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.signin-wrapper-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
}

.signin-wrapper-spt a {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.signin-wrapper-spt span {
  color: #ffffff;
  margin: 0 10px;
}

.notification-wrapper-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 10%;
  height: 100%;
}

.profile-wrapper-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
}

.profile-button-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  width: 150px;
  height: 35px;
  border-radius: 8px;
}

.profile-icon-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.profile-text-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

@media only screen and (max-width: 1024px) {
  .corporate-wrapper-spt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 100%;
    padding: 0 10px;
  }

  .corporate-text-spt {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    height: 35px;
    width: 150px;
    border-radius: 0 8px 8px 0;
    color: #212121;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 12px;
  }

  .signin-wrapper-spt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 100%;
    padding: 0 10px;
  }

  .signin-wrapper-spt a {
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1023px) {
  .topbar-wrapper-spt {
    display: none;
  }

  .topbar-mob-wrapper-spt {
    display: flex;
    height: 100px;
  }

  .logo-wrapper-spt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .logo-box-spt {
    width: 200px;
  }

  .mob-nav-link-spt {
    display: block !important;
    color: #484848 !important;
    font-size: 20px !important;
    margin-bottom: 5px !important;
    text-decoration: none;
  }

  /*------------------------------*/
  /* START OF CSS FOR HAMBURGER MENU */
  /*------------------------------*/

  *:focus {
    outline: none;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: relative;
    width: 32px;
    height: 20px;
    left: 25px;
    top: 36px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #f2f2f2;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #212121;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #484848;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #f2f2f2;
    padding: 2em 1.5em 0;
    font-size: 1em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #212121;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  /*------------------------------*/
  /* END OF CSS FOR HAMBURGER MENU */
  /*------------------------------*/
}

@media only screen and (max-width: 321px) {
  .bm-burger-button {
    position: relative;
    width: 28px !important;
    height: 17px !important;
    left: 15px !important;
    top: 36px !important;
  }
}
