.flight-results-flight-spt {
  width: 70%;
  height: 100%;
  padding: 20px 10px;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.288);
}

.flight-inner-flight-spt {
  width: 100%;
  height: 93%;
  padding-left: 10px;
  overflow-y: scroll;
}

.top-section-wrapper-spt {
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #c5c5c5;
  margin-bottom: 5px;
}

.top-section-flight-spt {
  position: sticky;
  display: flex;
  width: 99%;
  height: 50px;
}

.top-airline-section-item-flight-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 100%;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #212121;
}

.top-depart-section-item-flight-spt {
  display: flex;
  align-items: center;
  width: 15%;
  height: 100%;
  padding-left: 40px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #212121;
}

.top-arrival-section-item-flight-spt {
  display: flex;
  align-items: center;
  width: 15%;
  height: 100%;
  padding-left: 10px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #212121;
}

.top-duration-section-item-flight-spt {
  display: flex;
  align-items: center;
  width: 15%;
  height: 100%;
  padding-left: 15px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #212121;
}

.top-price-section-item-flight-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 100%;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #212121;
}

.middle-big-spt {
  flex: 3 0 auto;
}

/* Scrollbar Style */
/* width */
.flight-inner-flight-spt::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.flight-inner-flight-spt::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.flight-inner-flight-spt::-webkit-scrollbar-thumb {
  background: #0092cf;
  border-radius: 10px;
}

/* Handle on hover */
.flight-inner-flight-spt::-webkit-scrollbar-thumb:hover {
  background: #0092cf;
}
