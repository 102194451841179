.main-container-fb-spt {
  height: 100%;
  width: 100%;
  background-image: url("../../static/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  padding-top: 20px;
}

.mob-flightbooking-display-spt {
  display: none;
}

.flightbooking-wrapper-fb-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.flightbooking-container-fb-spt {
  width: 90%;
  min-height: 800px;
  height: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px;
}

.flightbooking-container-fb-spt h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 25px;
  padding: 5px 10px;
}

.flightbooking-details-wrapper-fb-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  margin: 20px 0px;
}

.flightbooking-flightsdetails-fb-spt {
  width: 75%;
  height: 100%;
  margin-right: 20px;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.flightbooking-flightfare-fb-spt {
  width: 25%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.flightbooking-title-fb-spt {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 25%;
  border-radius: 5px 5px 0px 0px;
  background-color: #0066cf;
}

.flightbooking-title-head-fb-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.flightbooking-price-head-fb-spt {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.flightbooking-details-fb-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75%;
  padding: 25px 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.flightbooking-airline-details-fb-spt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.flightbooking-airline-details-fb-spt h3 {
  color: #0066cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 25px;
}

.flightbooking-airline-details-fb-spt p {
  color: #484848;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 18px;
  padding: 10px 0px;
}

.flightbooking-depart-details-fb-spt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.flightbooking-depart-details-fb-spt h3 {
  text-align: center;
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 5px;
}

.flightbooking-depart-details-fb-spt h3 span {
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
  padding-bottom: 5px;
}

.flightbooking-depart-details-fb-spt p {
  text-align: center;
  color: #484848;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding-top: 5px;
}

.flightbooking-arrival-details-fb-spt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.flightbooking-arrival-details-fb-spt h3 {
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 5px;
}

.flightbooking-arrival-details-fb-spt h3 span {
  text-align: center;
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
  padding-bottom: 5px;
}

.flightbooking-arrival-details-fb-spt p {
  text-align: center;
  color: #484848;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding-top: 5px;
}

.flightbooking-refund-details-fb-spt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.flightbooking-refund-details-fb-spt h3 {
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 5px;
}

.flightbooking-refund-details-fb-spt p {
  color: #0066cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 15px 0px;
  cursor: pointer;
}

.flightbooking-modal-wrapper-spt {
  display: none;
}

.flightbooking-modal-display-spt {
  display: block;
}

.flightbooking-modal-container-spt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  margin-top: 70px;
  width: 90%;
  height: 100%;
  overflow-y: scroll;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.fb-modal-wrapper-spt {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 0px;
  text-align: justify;
}

.fb-modal-fareruletitle-spt {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  height: 100%;
  background-color: #0066cf;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
  padding: 5px 0px;
}

.fb-modal-fareruledetails-spt {
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 10px;
  padding: 0px 50px;
}

.fb-modal-fareruledetails-spt table {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  border-collapse: collapse;
  margin: 10px 0px;
}

.fb-modal-fareruledetails-spt table tbody {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #00000050;
}

.fb-modal-fareruledetails-spt table td b {
  color: #0066cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 12px;
  padding: 10px !important;
}

.fb-modal-fareruledetails-spt table td p {
  color: #0066cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  padding: 0px 10px;
  cursor: default;
}

.fb-modal-fareruledetails-spt table td {
  padding: 10px;
  color: #484848;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
}

.fb-close-modal-spt {
  position: absolute;
  top: 1%;
  right: 1%;
}

.flightbooking-stops-details-fb-spt {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.flightbooking-stops-details-fb-spt h3 {
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.flightbooking-duration-details-fb-spt {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.flightbooking-duration-details-fb-spt h3 {
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.flightbooking-price-details-fb-spt {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 75%;
  padding: 0px 20px;
}

.flightbooking-price-baseprice-fb-spt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flightbooking-price-baseprice-fb-spt h3 {
  color: #484848;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.flightbooking-price-baseprice-fb-spt p {
  color: #484848;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.flightbooking-price-tax-fb-spt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flightbooking-price-tax-fb-spt h3 {
  color: #484848;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.flightbooking-price-tax-fb-spt p {
  color: #484848;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.flightbooking-price-totalprice-fb-spt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flightbooking-price-totalprice-fb-spt h3 {
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.flightbooking-price-totalprice-fb-spt p {
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.flightbooking-passenger-head-fb-spt {
  color: #484848;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 20px;
  padding: 10px;
}

.flightbooking-passenger-mainform-fb-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.flightbooking-passenger-formcontainer-fb-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.flightbooking-pass-formwrap-fb-spt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding: 10px 25px;
}

.flightbooking-pass-name-fb-spt {
  display: flex;
  width: 95%;
  flex-direction: column;
  padding-bottom: 10px;
}

.flightbooking-pass-name-fb-spt p {
  color: #707070;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 2px 10px;
}

.flightbooking-pass-name-fb-spt input[type="text"] {
  display: flex;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.flightbooking-pass-mobile-fb-spt {
  display: flex;
  width: 95%;
  flex-direction: column;
  padding-bottom: 10px;
}

.flightbooking-pass-mobile-fb-spt p {
  color: #707070;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 2px 10px;
}

.flightbooking-pass-mobile-fb-spt input[type="text"] {
  display: flex;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.flightbooking-pass-email-fb-spt {
  display: flex;
  width: 95%;
  flex-direction: column;
  padding-bottom: 10px;
}

.flightbooking-pass-email-fb-spt p {
  color: #707070;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 2px 10px;
}

.flightbooking-pass-email-fb-spt input[type="email"] {
  display: flex;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.flightbooking-pass-dob-fb-spt {
  display: flex;
  width: 95%;
  flex-direction: column;
  padding-bottom: 10px;
}

.flightbooking-pass-dob-fb-spt p {
  color: #707070;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 2px 10px;
}

.flightbooking-singlepass-dob-fb-spt {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 10px;
}

.flightbooking-singlepass-dob-fb-spt p {
  color: #707070;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 2px 10px;
}

.dob-details-fb-spt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dob-details-fb-spt input[type="text"] {
  display: flex;
  width: 100%;
  margin: 0px 2px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.flightbooking-pass-address-fb-spt {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 10px;
}

.flightbooking-pass-address-fb-spt p {
  color: #707070;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 2px 10px;
}

.flightbooking-pass-address-fb-spt input[type="text"] {
  display: flex;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.flightbooking-pass-city-fb-spt {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 10px;
}

.flightbooking-pass-city-fb-spt p {
  color: #707070;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 2px 10px;
}

.flightbooking-pass-city-fb-spt input[type="text"] {
  display: flex;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.flightbooking-pass-postal-fb-spt {
  display: flex;
  width: 100%;
  padding-bottom: 10px;
}

.country-details-fb-spt {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-right: 20px;
}

.country-details-fb-spt p {
  color: #707070;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 2px 10px;
}

.country-details-fb-spt input[type="text"] {
  display: flex;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.postal-details-fb-spt {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.postal-details-fb-spt p {
  color: #707070;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 2px 10px;
}

.postal-details-fb-spt input[type="text"] {
  display: flex;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.passenger-booking-form-wrapper-spt {
  margin-top: 20px;
}

.passenger-passengerbooking-mainform-fb-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.flightbooking-meal-baggage-wrapper-fb-spt {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 100%;
  margin-bottom: 15px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  overflow: hidden;
}

.flightbooking-meal-baggage-title-fb-spt {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  background-color: #0066cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
}

.flightbooking-meal-baggage-body-fb-spt {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  background-color: #ffffff;
}

.flightbooking-meal-select-fb-spt {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0px 30px;
}

.flightbooking-meal-select-fb-spt p {
  color: #0066cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding-left: 10px;
  padding-bottom: 5px;
}

.flightbooking-meal-select-fb-spt select {
  display: flex;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.flightbooking-baggage-select-fb-spt {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0px 30px;
}

.flightbooking-baggage-select-fb-spt p {
  color: #0066cf;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding-left: 10px;
  padding-bottom: 5px;
}

.flightbooking-baggage-select-fb-spt select {
  display: flex;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.flightbooking-btn-container-fb-spt {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.flightbooking-seatmap-btn-fb-spt {
  display: flex;
  width: 20%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0px 15px;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #0066cf;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #0066cf;
  cursor: pointer;
}

.flightbooking-seatmap-btn-fb-spt:hover {
  transition: 0.2s;
  background-color: #0066cf;
  color: #ffffff;
}

.flightbooking-booking-btn-fb-spt {
  display: flex;
  width: 20%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0px 15px;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #0066cf;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #0066cf;
  cursor: pointer;
}

.flightbooking-booking-btn-fb-spt:hover {
  transition: 0.2s;
  background-color: #0066cf;
  color: #ffffff;
}

@media only screen and (max-width: 1023px) {
  .main-container-fb-spt {
    display: none;
  }

  .mob-flightbooking-display-spt {
    width: 100%;
    height: 100%;
  }

  .flightbooking-modal-container-spt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 990;
    margin-top: 100px;
    width: 90%;
    height: 100%;
    overflow-y: scroll;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }

  .fb-modal-wrapper-spt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 20px 10px;
    text-align: justify;
  }

  .fb-modal-fareruletitle-spt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    height: 100%;
    background-color: #0066cf;
    border-radius: 5px;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 20px;
    padding: 5px 0px;
  }

  .fb-modal-fareruledetails-spt {
    color: #212121;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 8px;
    padding: 0px 5px;
  }

  .fb-modal-fareruledetails-spt table {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    border-collapse: collapse;
    margin: 1px 0px;
  }

  .fb-modal-fareruledetails-spt table tbody {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #00000050;
  }

  .fb-modal-fareruledetails-spt table td b {
    color: #0066cf;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 8px;
    padding: 5px !important;
  }

  .fb-modal-fareruledetails-spt table td p {
    color: #0066cf;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 8px;
    padding: 0px 5px;
    cursor: default;
  }

  .fb-modal-fareruledetails-spt table td {
    padding: 5px;
    color: #484848;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 8px;
  }
}
