.flight-loading-spt {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.877);
  z-index: 999;
}

.loader-loading-sp {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
}

.loader-loading-sp h1 {
  display: inline;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  color: #0092cf;
  font-weight: 500;
}
