.mainarea-wrapper-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
}

.mob-mainarea-wrapper-spt {
  display: none;
}

.mainarea-spt {
  display: flex;
  width: 100%;
  height: 450px;
  background-color: #ffffffaa;
  border-radius: 0 0 20px 20px;
}

.form-wrapper-spt {
  width: 60%;
  height: 100%;
}

.formtitle-wrapper-spt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 25%;
  width: 100%;
  padding: 0 35px;
}

.formtitle-wrapper-spt h1 {
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.formtitle-wrapper-spt p {
  color: #212121;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 3px 0 15px 0;
}

.formtitle-wrapper-spt hr {
  border: 0.5px solid #21212150;
}

.journeytype-wrapper-spt {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 10%;
  width: 100%;
  padding: 0 20px 0 34px;
}

.container-spt {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-right: 25px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.container-spt input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
  border: #0092cf 1px solid;
}

/* On mouse-over, add a grey background color */
.container-spt:hover input ~ .checkmark {
  background-color: #0092cf;
}

/* When the radio button is checked, add a blue background */
.container-spt input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-spt input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-spt .checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #0092cf;
}

.destination-wrapper-spt {
  display: flex;
  align-items: center;
  height: 15%;
  width: 100%;
  padding: 0 35px;
}

.departure-wrapper-spt input[type="text"] {
  width: 100%;
  padding: 15px 35px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.destinationicon-wrapper-spt {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 48px;
  height: 45px;
  border-radius: 50%;
  margin-left: -5px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.212);
  z-index: 1;
}

.arrival-wrapper-spt input[type="text"] {
  position: relative;
  left: -5px;
  width: 100%;
  padding: 15px 35px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.class-wrapper-spt {
  display: flex;
  align-items: center;
  height: 15%;
  width: 100%;
  padding: 0 35px;
}

.select-class-spt {
  display: flex;
  width: 100%;
}

.select-class-spt select {
  display: flex;
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.departure-date-spt {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-left: 20px;
}

[type="date"] {
  background: #fff url("../../../static/icon-cal.png") 95% 50% no-repeat;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.departure-date-spt input[type="date"]::before {
  content: attr(data-placeholder);
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.departure-date-spt input[type="date"]::-webkit-datetime-edit {
  font-size: 0px;
}

.departure-date-spt input[type="date"] {
  display: flex;
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.arrival-date-spt {
  display: flex;
  width: 100%;
  margin-left: 20px;
}

.arrival-date-spt input[type="date"]::before {
  content: attr(data-placeholder);
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.arrival-date-spt input[type="date"]::-webkit-datetime-edit {
  font-size: 0px;
}

.arrival-date-spt input[type="date"] {
  width: 100%;
  padding: 10px 10px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.person-wrapper-spt {
  display: flex;
  align-items: center;
  height: 15%;
  width: 100%;
  padding: 0 35px;
}

.adult-count-spt {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 170px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.adult-count-spt p {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.adultcounter-spt {
  background-color: #e9e9e9;
  padding: 10px;
  margin-left: 5px;
}

.children-count-spt {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0px 18px;
  width: 170px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.children-count-spt p {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.childrencounter-spt {
  background-color: #e9e9e9;
  padding: 10px;
  margin-left: 5px;
}

.infant-count-spt {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 170px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.infant-count-spt p {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.infantcounter-spt {
  background-color: #e9e9e9;
  padding: 10px;
  margin-left: 5px;
}

.submit-wrapper-spt {
  display: flex;
  align-items: center;
  height: 20%;
  width: 100%;
  padding: 0 35px;
}

.submit-btn-spt {
  width: 260px;
  height: 35px;
  background-color: #0092cf;
  border: none;
  color: white;
  text-align: center;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.directflight-btn-spt {
  width: 260px;
  height: 35px;
  margin-right: 20px;
  background-color: #fff;
  border: none;
  text-align: center;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.advertise-wrapper-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
}

.image-wrapper-spt {
  width: 380px;
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 1023px) {
  .mainarea-wrapper-spt {
    display: none;
  }

  .mob-mainarea-wrapper-spt {
    display: flex;
    justify-content: center;
    margin-top: -10px;
    padding-bottom: 20px;
    height: 530px;
  }

  .mob-mainarea-roundtrip-wrapper-spt {
    display: flex;
    justify-content: center;
    margin-top: -10px;
    padding-bottom: 20px;
    height: 600px;
  }

  .mob-mainarea-spt {
    display: flex;
    width: 90%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 0 0 20px 20px;
  }

  .mob-form-wrapper-spt {
    width: 100%;
    height: 100%;
  }

  .formtitle-wrapper-spt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 25%;
    width: 100%;
    padding: 0 35px;
  }

  .formtitle-wrapper-spt h1 {
    color: #212121;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

  .formtitle-wrapper-spt p {
    color: #212121;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    margin: 3px 0 15px 0;
  }

  .formtitle-wrapper-spt hr {
    border: 0.5px solid #21212150;
  }

  .mob-journeytype-wrapper-spt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 10px;
  }

  .journeytype-list-spt {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .container-spt {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-right: 25px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 11px;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: #eee;
    border-radius: 50%;
    border: #0092cf 1px solid;
  }

  .container-spt .checkmark:after {
    top: 1px;
    left: 1px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #0092cf;
  }

  .departure-wrapper-spt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 25px;
  }

  .departure-wrapper-spt span {
    width: 35px;
    height: 35px;
    background-color: #ffffff;
    padding: 8px;
    margin-right: 10px;
    border-radius: 100px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  }

  .departure-wrapper-spt input[type="text"] {
    width: 80%;
    height: 35px;
    padding: 15px;
    display: inline-block;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
  }

  .arrival-wrapper-spt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
  }

  .arrival-wrapper-spt span {
    width: 35px;
    height: 35px;
    background-color: #ffffff;
    padding: 9px;
    margin-right: 10px;
    border-radius: 100px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  }

  .arrival-date-spt input[type="date"]::-webkit-datetime-edit {
    font-size: 0px;
  }

  .arrival-wrapper-spt input[type="text"] {
    position: relative;
    left: 0px;
    width: 80%;
    height: 35px;
    padding: 15px;
    display: inline-block;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
  }

  .select-class-spt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .select-class-spt select {
    width: 90%;
    height: 35px;
    margin-top: 15px;
    padding: 5px;
    display: inline-block;
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
  }

  select {
    -webkit-appearance: none;
  }

  .departure-date-spt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 90%;
  }

  [type="date"] {
    background: #fff url("../../../static/icon-cal.png") 95% 50% no-repeat;
  }
  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }
  [type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  .departure-date-spt input[type="date"]::before {
    content: attr(data-placeholder);
    display: flex;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #212121;
  }

  .departure-date-spt input[type="date"] {
    display: flex;
    width: 100%;
    padding: 10px 10px;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    font-size: 0px;
  }

  .departure-date-spt input[type="date"]::-webkit-datetime-edit {
    font-size: 0px;
  }

  .arrival-date-spt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 90%;
  }

  .arrival-date-spt input[type="date"]::before {
    content: attr(data-placeholder);
    display: inline-block;
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #212121;
  }

  .arrival-date-spt input[type="date"] {
    display: flex;
    width: 100%;
    padding: 10px 10px;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    font-size: 0px;
  }

  .arrival-date-spt input[type="date"]::-webkit-datetime-edit {
    font-size: 0px;
  }

  .mob-person-wrapper-spt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 10px;
  }

  .adult-count-spt {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    height: 30px;
    margin-top: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  }

  .adult-count-spt p {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 10px;
  }

  .adultcounter-spt {
    background-color: #e9e9e9;
    padding: 8px 10px;
    margin-left: 5px;
  }

  .children-count-spt {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    height: 30px;
    margin-top: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  }

  .children-count-spt p {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 10px;
  }

  .childrencounter-spt {
    background-color: #e9e9e9;
    padding: 8px 10px;
    margin-left: 5px;
  }

  .infant-count-spt {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    height: 30px;
    margin-top: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  }

  .infant-count-spt p {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
  }

  .infantcounter-spt {
    background-color: #e9e9e9;
    padding: 8px 10px;
    margin-left: 5px;
  }

  .submit-wrapper-spt {
    display: flex;
    align-items: center;
    height: 15%;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .submit-btn-spt {
    width: 260px;
    height: 35px;
    background-color: #0092cf;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    border: none;
    color: white;
    text-align: center;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
  }

  .directflight-btn-spt {
    width: 260px;
    height: 35px;
    margin-right: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    border: none;
    text-align: center;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
  }
}
