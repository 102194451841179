.mob-singleflight-card-spt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25%;
  margin: 5px 0px;
  padding: 10px 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.mob-singleflight-details-spt {
  display: flex;
  width: 100%;
  height: 100%;
}

.mob-singleflight-flights-spt {
  display: flex;
  width: 75%;
  height: 100%;
  margin-left: 3px;
}

.mob-singleflight-price-spt {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 25%;
  height: 100%;
}

.mob-singleflight-price-spt p {
  margin-bottom: 2px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #0092cf;
}

.mob-book-button-sf-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 72px;
  background-color: #0092cf;
  border-radius: 5px;
  cursor: pointer;
}

.mob-book-button-sf-spt p {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  color: white;
}

.mob-singleflight-depart-spt {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
}

.mob-singleflight-depart-spt h6 {
  margin-bottom: 8px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #212121;
}

.mob-singleflight-depart-spt p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #aaaaaa;
}

.mob-singleflight-gap-spt {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;
}

.mobile-flighttakeoff-icon-spt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mob-singleflight-gap-spt hr {
  margin-top: 16px;
  width: 80%;
  border: 0.5px solid #777777;
}

.mob-singleflight-arrival-spt {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  padding-left: 10px;
}

.mob-singleflight-arrival-spt h6 {
  margin-bottom: 8px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #212121;
}

.mob-singleflight-arrival-spt p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #aaaaaa;
}

.mob-singleflight-summary-spt {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60%;
}

.mob-singleflight-summary-spt p {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #777777;
}

.mob-singleflight-duration-spt {
  color: #484848;
}

.mob-singleflight-airline-spt {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60%;
}

.mob-singleflight-airline-spt p {
  margin-left: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #0092cf;
}
